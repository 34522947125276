import { initSentry } from 'app/config/configSentry';
import { setupAxios } from 'app/utils/utils';
import axios from 'axios';
import React from 'react';
import 'react-day-picker/dist/style.css';
import ReactDOM from 'react-dom';
import 'react-lemonade-editor/dist/index.css';
import 'react-mint-editor/dist/style.css';
import 'react-videolesson/dist/style.css';
import 'regenerator-runtime/runtime';
import App from './App';
import * as env from './app/config/environment';
import store, { persistor } from './app/store/store';
import './index.scss';

const { PUBLIC_URL, REACT_APP_WEBSITE_NAME, REACT_APP_VERSION, REACT_APP_VERSION_DATE, NODE_ENV } = process.env;
const { API_URL } = env[process.env.REACT_APP_ENV] || env.defaultConfig;

initSentry();
setupAxios(axios, API_URL, store);

ReactDOM.render(<App store={store} persistor={persistor} basename={PUBLIC_URL} />, document.getElementById('root'));

console.log(
  `%c${process.env.REACT_APP_WEBSITE_NAME} v${process.env.REACT_APP_VERSION} - ${process.env.REACT_APP_VERSION_DATE}`,
  'color: #007aff; font-weight: bold;'
);
