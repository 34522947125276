import { Viewer } from 'tangerine-viewer';
import { defaultConfig } from '../../config/environment';

import { useEffect, useState } from 'react';
import './styles/viewersv2.scss';

const ViewersContainerV2 = (props) => {
  const [contentGuid, setContentGuid] = useState(null);
  const [Config, setConfig] = useState(null);

  useEffect(() => {
    console.log('props', props);
    console.log('props.match.params.guid', props.match.params.id);
    console.dir('visor');

    const API_URL = defaultConfig.API_URL;
    const user = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user);
    const token = JSON.parse(localStorage.getItem('persist:i2c_auth')).authToken.replaceAll('"', '');

    const config = {
      domain: API_URL.replace('/api', ''),
      role: user.role_guid,
      authToken: token,
      user: {
        guid: user.guid,
        name: user.name,
        surname: user.lastname,
        role: user.role_guid,
      },
    };

    setConfig(config);

    setContentGuid(props.match.params.id);

    console.log('USER', user);
    console.log('token', token);
    console.log('config', config);
  }, []);

  return (
    contentGuid &&
    Config && (
      <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
        <Viewer
          contentGuid={contentGuid}
          domain={Config.domain}
          role={Config.role}
          authToken={Config.authToken}
          user={Config.user}
          lang={'es'}
          // courseGuid={`b87a9b50-c72e-11ee-ad98-9dfb76501f69`}
          scormMetadata={{}}
          pdfConfig={[
            'TOC_AND_THUMBNAILS',
            'COMMENTS',
            'HOTSPOTS',
            'HAND',
            'SELECT_TEXT',
            'DRAWING',
            'TEXT',
            'SHAPES',
            'ERASER',
            'SEARCH',
            'PRINT',
            'VIEW_CONTROLS',
            'ZOOM_IN',
            'ZOOM_OUT',
          ]}
          // showHeader={true}
          // onClose={() => {
          //   history.back();
          // }}
          // debugMode={true}
        />
      </div>
    )
  );
};

export default ViewersContainerV2;
