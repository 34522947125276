import axios from 'axios';
import viewerApi from '../api/viewerApi';
import { API_URL, IS_DEBUG_MODE } from './viewerConfig';
import { getDebugFile } from './viewerDebug';
import { getDomain } from './viewerUtils';

export const contentsTypes = [
  { guid: 'CTTY_00', progress: false, type: null, mustGetFile: true, labelDefault: 'Dependency', hasDependency: false },
  { guid: 'CTTY_01', progress: false, type: 'fixed_layout', mustGetFile: true, labelDefault: 'Book Fixed Layout', hasDependency: false },
  { guid: 'CTTY_02', progress: false, type: 'mint_book', mustGetFile: true, labelDefault: 'Book Mint', hasDependency: false },
  { guid: 'CTTY_03', progress: true, type: 'video', mustGetFile: true, labelDefault: 'Video', hasDependency: false, hotspotType: 'videoLink' },
  { guid: 'CTTY_04', progress: true, type: 'audio', mustGetFile: true, labelDefault: 'Audio', hasDependency: false, hotspotType: 'audioLink' },
  { guid: 'CTTY_05', progress: false, type: 'pdf', mustGetFile: true, labelDefault: 'PDF', hasDependency: false, hotspotType: 'pdfLink' },
  { guid: 'CTTY_06', progress: false, type: 'link', mustGetFile: false, labelDefault: 'Link', hasDependency: false, hotspotType: 'urlLink' },
  { guid: 'CTTY_07', progress: false, type: 'image', mustGetFile: true, labelDefault: 'Image', hasDependency: false, hotspotType: 'imageLink' },
  {
    guid: 'CTTY_08',
    progress: false,
    type: 'html_interactive',
    mustGetFile: true,
    labelDefault: 'HTML Interactive',
    hasDependency: false,
    hotspotType: 'htmlLink',
  },
  { guid: 'CTTY_09', progress: false, type: 'test', mustGetFile: true, labelDefault: 'Test', hasDependency: false },
  { guid: 'CTTY_10', progress: false, type: 'scorm', mustGetFile: true, labelDefault: 'SCORM', hasDependency: true, hotspotType: 'scormLink' },
  { guid: 'CTTY_11', progress: false, type: 'swf', mustGetFile: true, labelDefault: 'SWF', hasDependency: false },
  { guid: 'CTTY_12', progress: false, type: 'pdf', mustGetFile: true, labelDefault: 'OFFICE', hasDependency: false, hotspotType: 'pdfLink' },
  { guid: 'CTTY_13', progress: false, type: 'zip', mustGetFile: true, labelDefault: 'Zip File', hasDependency: false },
  {
    guid: 'CTTY_14',
    progress: false,
    type: 'question',
    mustGetFile: false,
    labelDefault: 'Question',
    hasDependency: true,
    hotspotType: 'lemonadeExerciseLink',
  },
  { guid: 'CTTY_15', progress: false, type: 'html_page', mustGetFile: true, labelDefault: 'HTML Page', hasDependency: false },
  { guid: 'CTTY_18', progress: false, type: 'app', mustGetFile: false, labelDefault: 'App', hasDependency: false },
  { guid: 'CTTY_20', progress: false, type: 'xapi', mustGetFile: true, labelDefault: 'xAPI', hasDependency: false },
  { guid: 'CTTY_21', progress: false, type: 'epub', mustGetFile: true, labelDefault: 'ePub', hasDependency: false },
  { guid: 'CTTY_CATBOOK', progress: false, type: 'cat_book', mustGetFile: true, labelDefault: 'Book CAT', hasDependency: true },
  { guid: 'CTTY_EPUB_COLIBRIO', progress: false, type: 'epub_colibrio', mustGetFile: true, labelDefault: 'Book Epub Colibrio', hasDependency: false },
];

export const getContentsTypesName = (_content) => {
  let _labelDefault = contentsTypes.find((o) => o.guid === _content.type_guid);
  switch (_content.type_guid) {
    case 'CTTY_CATBOOK':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_CATBOOK || _labelDefault.labelDefault;
    case 'CTTY_EPUB_COLIBRIO':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_EPUB_COLIBRIO || _labelDefault.labelDefault;
    case 'CTTY_20':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_XAPI || _labelDefault.labelDefault;
    case 'CTTY_21':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_EPUB || _labelDefault.labelDefault;
    case 'CTTY_01':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_FIXED_LAYOUT || _labelDefault.labelDefault;
    case 'CTTY_02':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_MINT_BOOK || _labelDefault.labelDefault;
    case 'CTTY_03':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_VIDEO || _labelDefault.labelDefault;
    case 'CTTY_04':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_AUDIO || _labelDefault.labelDefault;
    case 'CTTY_05':
    case 'CTTY-16':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_PDF || _labelDefault.labelDefault;
    case 'CTTY_06':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_VIDEO || _labelDefault.labelDefault;
    case 'CTTY_07':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_IMAGE || _labelDefault.labelDefault;
    case 'CTTY_08':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_HTML_INTERACTIVE || _labelDefault.labelDefault;
    case 'CTTY_12':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_OFFICE || _labelDefault.labelDefault;
    case 'CTTY_10':
      return window.ocviewerlang.VISOR_LOADING_LABEL_TYPE_SCORM || _labelDefault.labelDefault;
    default:
      return null;
  }
};

export function getContent(_content_data, _callback) {
  const _data = { content_guid: _content_data.content_guid, studentGuid: _content_data.studentGuid, type: 'GET_CONTENTS' };

  viewerApi(_data, function (e) {
    _callback(e);
  });
}

export function evaluateContentError(_content_data, _callback) {
  let _evaluate = {
    error: false,
    value: '',
    content: _content_data,
  };

  try {
    if (
      typeof _content_data.encoded_url !== 'undefined' &&
      _content_data.encoded_url !== '' &&
      _content_data.encoded_url !== null &&
      _content_data.type_guid != 'CTTY_18'
    ) {
      _content_data.url = _content_data.encoded_url;
    }
  } catch (encoded_url_err) {
    console.log(encoded_url_err);
  }

  if (_content_data.response != undefined) {
    try {
      if (_content_data.response.data.error.code == 403) {
        _evaluate.error = true;
        _evaluate.value = 'ERROR_403';
        _callback(_evaluate);
        return;
      }
    } catch (e) {}
    try {
      if (_content_data.response.data.error.code == 404) {
        _evaluate.error = true;
        _evaluate.value = 'ERROR_404';
        _callback(_evaluate);
        return;
      }
    } catch (e) {}
    try {
      if (_content_data.response.data.error.code == 500) {
        _evaluate.error = true;
        _evaluate.value = 'ERROR_500';
        _callback(_evaluate);
        return;
      }
    } catch (e) {}
    try {
      _evaluate.error = true;
      _evaluate.value = _content_data.response.data.error.message + ' - ' + _content_data.response.data.error.code;
      _callback(_evaluate);
      return;
    } catch (e) {}
  }

  _content_data.videoType = 'null';

  try {
    if (_content_data.url.indexOf('https://vimeo.com/') >= 0) {
      _content_data.videoType = 'vimeo';
      _content_data.type_guid = 'CTTY_03';
    }
  } catch (err_vi) {
    console.error('Error subtype Vimeo');
  }

  _evaluate.content = _content_data;

  _callback(_evaluate);
}

export function getShowAt(_props, _type) {
  let _show_at =
    _props && _props.match && _props.match.params && _props.match.params.show_at && _props.match.params.show_at === 'visor' ? '' : _props.match.params.show_at;
  try {
    if (_type == 'mint_book') {
      if (window.location.hash != '#/visor' && window.location.hash != '' && window.location.hash.indexOf('/lessons/') >= 0) {
        _show_at = window.location.hash.split('/lessons/')[1];
      }
    }
  } catch (e) {}

  return _show_at;
}

export function getFileStorage(_type, _url, _headers, API_URL, _callback) {
  let responseUrl = '';

  if (_type === 'CTTY_10' && _url.includes('index.html')) {
    _url = _url.replace('index.html', 'imsmanifest.xml');
  }

  if (IS_DEBUG_MODE && typeof _callback === 'function') {
    _callback(getDebugFile(_type));
    return;
  }

  _url = IS_DEBUG_MODE || (_url && _url.startsWith('https://')) ? _url : API_URL.replace('/api/', '') + _url;
  if (!IS_DEBUG_MODE && _url.indexOf('files-storage') < 0) {
    _callback(_url);
    return;
  }

  if(_url.indexOf('files-storage/open') >= 0){
    _url= _url.indexOf('?') >= 0 ? _url + '&auth=cookie' : _url + '?auth=cookie'
  }

  axios
    .get(_url, {
      headers: _headers,
      maxRedirects: 0,
    })
    .then(
      (response) => {
        responseUrl = response && response.data && response.data.data && response.data.data.url ? response.data.data.url : _url;

        if (!IS_DEBUG_MODE) {
          let responseCookies = response && response.data && response.data.data && response.data.data.cookies ? response.data.data.cookies : [];
          responseCookies.forEach((cookie) => {
            let _cookie = cookie.replace(' Secure; HttpOnly; SameSite=None', '');
            console.log('Write cookie: ', _cookie);
            document.cookie = _cookie;
          });
        }

        _callback(responseUrl);
      },
      (error) => {
        console.log(JSON.stringify(error));
        try {
          if (JSON.stringify(error).indexOf('403') >= 0) {
            _callback('FILE_FORBIDDEN');
          } else {
            _callback('FILE_NOT_FOUND');
          }
        } catch (errsd) {
          console.log(errsd);
          _callback('FILE_FORBIDDEN');
        }
      }
    );
}

export function getMetaScraper(_content, _data, _callback) {
  var _data_meta = { url: _content.url, type: 'GET_METASCRAPER' };

  viewerApi(_data_meta, function (_e_meta) {
    if (_e_meta.error) {
      _e_meta.iframe_blocked = true;
    } else {
      var arr = String(_e_meta.url).split('/');
      var protocol = String(arr[0]).toLocaleLowerCase();
      if (String(window.location.protocol).toLocaleLowerCase() !== protocol) {
        console.log('Mixed content detected, then, show card!');
        _e_meta.iframe_blocked = true;
      } else {
        _e_meta.iframe_blocked = false;
      }
    }

    _data.description = _e_meta.description || _data.description;
    _data.iframe_blocked = _e_meta.iframe_blocked;
    _data.thumbnail = _e_meta.image || _data.thumbnail;
    _data.imageBase64 = _e_meta.imageBase64;
    _data.url = _e_meta.url || _data.url || _content.url;
    _data.name = _e_meta.title || _data.name;

    _callback(_data);
  });
}

export function getAccesAppContent(_content, _data, _callback) {
  viewerApi({ type: 'GET_USERS_ACCESS_TOKEN' }, function (data_token) {
    _data.type = 'html_interactive';
    _data.url = _data.url + '?token=' + data_token.data.token + '&education_year=' + _content.education_year;
    _callback(_data);
  });
}

export function getExtraData(_data, _content, _extradata, _this, _callback) {
  _extradata.isOwner = _content.type_guid == 'CTTY_05' || _content.type_guid == 'CTTY_12' ? true : false;
  _extradata.enableSearch = _content.type_guid == 'CTTY_05' || _content.type_guid == 'CTTY_12' ? true : false;

  if (_content.customTags.length > 0) {
    let obj_sh = _content.customTags.find((o) => o.type === 'ENABLE_SEARCH');
    if (obj_sh.name == 'YES') {
      _extradata.enableSearch = true;
    }

    let obj_sh_2 = _content.customTags.find((o) => o.type === 'START_PAGE');
    if (obj_sh_2 != undefined) {
      _extradata.customStartPage = obj_sh_2.name;
      _extradata.customStartPageText = 'Capa';
    }
  }

  if (_content.type_guid == 'CTTY_01') {
    _extradata.defaultPagesOrientation = 'FacingCover';
    _extradata.pagesOrientationSingle = true;
    _extradata.pagesOrientationDouble = false;
    _extradata.shareToMicrosoft = function (hotspot) {};
    _extradata.shareToGoogle = function (hotspot) {};
    _extradata.getInternalData = function () {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let _courseGuid = urlParams.get('courseGuid') || 'viewer'; // GUID del Curso
      return {
        courseGuid: _courseGuid,
        contentBookGuid: _this.props.match.params.guid,
        url: API_URL.replace('/api/', ''),
      };
    };
  }

  _extradata.BATCH_ANNOTS_DELETE = true;

  _extradata.guid_book = _data.content_guid;

  // PPN - NPP
  if (_content.type_guid == 'CTTY_01' && _content.customTags && _content.customTags.length > 0) {
    // Primera página numerada
    _extradata.ppn = 1;
    try {
      _extradata.ppn = parseInt(_content.customTags.find((ctg) => ctg.type === 'LED_START_PAGE').name);
    } catch (_ppnnppp_er) {}
    // Número primera página
    _extradata.npp = 1;
    try {
      _extradata.npp = parseInt(_content.customTags.find((ctg) => ctg.type === 'LED_NUMBER_PAGE').name);
    } catch (_ppnnppp_er) {}
  } else if (_content.type_guid == 'CTTY_01') {
    // Primera página numerada
    _extradata.ppn = 1;
    // Número primera página
    _extradata.npp = 1;
  }
  _extradata.ppn = 1;
  // Número primera página
  _extradata.npp = 1;
  // END PPN - NPP

  if (_content.is_downloadable) {
    _extradata.isOwner = true;
    _extradata.onClickShare = _this.onClickShare;

    var urlForShare = '//' + getDomain(window.location.href, false) + '/viewer/' + _content.guid;

    _extradata.sharedListElements = [
      { type: 'whatsapp', url: !!_content.is_public ? _content.url_public_viewer : urlForShare },
      { type: 'mail', url: !!_content.is_public ? _content.url_public_viewer : urlForShare },
    ];
  }

  if (_content.type_guid === 'CTTY_01' || _content.type_guid === 'CTTY_12' || _content.type_guid === 'CTTY_05') {
    _extradata.getAnnotationsPages = function (_pages, _callback) {
      const dataSendAnnots = {
        type: 'GET_ANNOTATIONS_PAGES',
        contentGuid: _data.content_guid,
        parent: _pages,
      };

      viewerApi(dataSendAnnots, function (e) {
        _callback(e);
      });

      // _callback(_pages)
    };
  }

  _callback(_extradata);
}

export function getDataCommonElements(_content_guid, _callback) {
  var _data_b = {
    content_guid: _content_guid,
    type: 'GET_COMMON_ELEMENTS',
  };

  viewerApi(
    _data_b,
    function (e_common_elements) {
      _callback(e_common_elements);
    }.bind(this)
  );
}
