import Loading from 'app/components/Loading/LoadingScreen';
import { initFeatureFlag } from 'app/config/configFeatureFlag';
import { initializeLogin } from 'app/config/initializeApp';
import { initializeLanguage } from 'app/config/initializeLanguage';
import { initializeLemonade } from 'app/config/initializeLemonade';
import { initializePersonalice } from 'app/config/initializePersonalice';
import RoutesContext from 'app/context/routes.context';
import useFeatureFlagRoutes from 'app/hooks/useFeatureFlagRoutes';
import LogoutPage from 'app/pages/auth/LogoutPage';
import HomePage from 'app/pages/home/HomePage';
import ScormNormalize from 'app/pages/scormNormalize';
import UIPage from 'app/pages/ui/UIPage';
import ViewersContainer from 'app/pages/viewers/ViewersContainer';
import ViewersContainerV2 from 'app/pages/viewersv2/ViewersContainerV2';
import { canAccess } from 'app/policies/routes.access';
import * as routerHelpers from 'app/services/router.service';
import { getRedirect } from 'app/services/routes.service';
import { app, scopes, systemSettings } from 'app/store/index';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import routes from './routesConfig';
import routesAuth from './routesConfigAuth';

export const Routes = withRouter(() => {
  const flags = useFeatureFlagRoutes();
  const SCOPES = useSelector((state) => scopes.selectors.getScopes(state));
  const isLoading = useSelector((state) => app.selectors.getLoading(state));
  const lastLocation = useLastLocation();
  const location = useLocation();
  const organization = useSelector((state) => systemSettings.selectors.getOrganization(state));
  const settings = useSelector((state) => systemSettings.selectors.getSetting(state));
  const [transitionPage, setTransistionPage] = React.useState('fadeIn'); // fadeIn, fadeOut
  const [displayLocation, setDisplayLocation] = React.useState(undefined);
  const { isAuthorized, user } = useSelector(
    ({ auth }) => ({
      hasAuthToken: Boolean(auth.authToken),
      isAuthorized: auth.user != null,
      roleUser: (auth.user && auth.user.role_guid) || null,
      user: auth.user,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );
  const redirectUrl = getRedirect(user);

  if (lastLocation && isAuthorized) {
    routerHelpers.saveLastLocation(lastLocation);
  }

  function onAnimationEnd() {
    if (transitionPage === 'fadeOut') {
      setTransistionPage('fadeIn');
      setDisplayLocation(location);
    }
  }

  React.useEffect(() => {
    if (location !== displayLocation) {
      setTransistionPage('fadeOut');
    }
  }, [location]);

  React.useEffect(() => {
    initializeLanguage(user);
    if (isAuthorized) {
      initializeLogin();
    }

    return () => {
      setDisplayLocation(undefined);
    };
  }, [isAuthorized]);

  React.useEffect(() => {
    if (isAuthorized) {
      initializeLemonade({ showLemonadeAiFeedback: flags?.showLemonadeAiFeedback, user });
    }
  }, [isAuthorized, flags?.showLemonadeAiFeedback]);

  React.useEffect(() => {
    initializePersonalice({ organization, scopes: SCOPES });

    if (organization) {
      initFeatureFlag({ organization, user });
    }
  }, [organization]);

  return (
    <RoutesContext.Provider value={{ transitionPage }}>
      <div onAnimationEnd={onAnimationEnd}>
        <Switch location={displayLocation}>
          <Route path="/logout" component={LogoutPage} />

          {!isAuthorized ? (
            <Switch>
              {routesAuth.map((item) => (
                <Route key={item.path} path={item.path} component={item.component} />
              ))}

              <Redirect to="/auth/login" from="/auth" exact={true} />
              <Redirect to="/auth/login" />
            </Switch>
          ) : (
            <Redirect from="/auth" to={redirectUrl} />
          )}

          {!isAuthorized ? (
            <Redirect to="/auth/login" />
          ) : (
            <Switch>
              <Route path="/home" render={() => <HomePage />} />
              <Route path="/ui" render={() => <UIPage />} />
              <Route path="/viewerv2/:id" render={(props) => <ViewersContainerV2 {...props} />} />
              <Route path="/viewer/:id" render={() => <ViewersContainer />} />
              <Route path="/scormnormalize" render={() => <ScormNormalize />} />

              {routes.map((item) =>
                canAccess({ flags, path: item.path, settings, user }) ? <Route key={item.path} path={item.path} component={item.component} /> : null
              )}

              <Redirect from="*" to={redirectUrl} />
            </Switch>
          )}
        </Switch>
      </div>

      {isLoading && <Loading />}
    </RoutesContext.Provider>
  );
});
