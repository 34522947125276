import { createContent, updateContent } from 'app/crud/contents.crud';
import { postTags } from 'app/crud/tags.crud';
import { getMetadataArray } from 'app/services/metadata.service';
import store from 'app/store/store';
import { isString } from 'lodash';

export function createContentService(data) {
  const params = getDataCreate(data);

  return createContent(params);
}

export async function updateContentService(data) {
  const newData = await getTags(data);
  const params = getDataUpdate(newData);

  return updateContent(params);
}

export function parseFieldData(data) {
  return isString(data) ? JSON.parse(data) : data;
}

export function getMimeType(content, typesContents = []) {
  const { entities } = store.getState();
  const types = typesContents.length ? typesContents : entities.types.data;
  const find = types.find((item) => item.guid === content?.type_guid);

  if (find) {
    const format = find.formats.map((item) => item['format']);
    const mimeType = find.formats.map((item) => item['mime-type']);

    return [format, mimeType];
  }

  return [[], []];
}

export function getIsContentUrl(content) {
  const isUrl = content.type_guid;
  const isVideoLesson = content.type_guid === TYPES_CONTENTS.CTTY_VIDEOLESSON;
  const isQuestion = content.type_guid === TYPES_CONTENTS.CTTY_LEMONADE;
  const isMintBlock = content.type_guid === TYPES_CONTENTS.CTTY_MINTBLOCK;
  const isQuiz = content.type_guid === TYPES_CONTENTS.CTTY_QUIZ;

  return isUrl && !isVideoLesson && !isQuestion && !isMintBlock && !isQuiz;
}

export function getNameFileContent(content, file) {
  const find = CONTENTS_XOD.find((item) => item === content?.type_guid);
  const extensionFile = file.name.split('.').pop();
  return find ? `${content?.guid}.${extensionFile}` : undefined;
}

export const TYPES_CONTENTS = {
  CTTY_LEMONADE: 'CTTY_14',
  CTTY_LTI: 'CTTY_LTI',
  CTTY_MINTBLOCK: 'CTTY_MINTBLOCK',
  CTTY_QUIZ: 'CTTY_QUIZ',
  CTTY_TASK: 'CTTY_14',
  CTTY_VIDEOLESSON: 'CTTY_VIDEOLESSON',
  FIXED_LAYOUT: 'CTTY_01',
  HTML_interactive: 'CTTY_08',
  LDR_MINT: 'CTTY_02',
  OFFICE: 'CTTY_12',
  PDF: 'CTTY_05',
  ZIP: 'CTTY_13',
  app: 'CTTY_18',
  audio: 'CTTY_04',
  dependency: 'CTTY_00',
  image: 'CTTY_07',
  link: 'CTTY_06',
  lti: 'CTTY_LTI',
  question: 'CTTY_14',
  scorm: 'CTTY_10',
  test: 'CTTY_09',
  video: 'CTTY_03',
  videolesson: 'CTTY_VIDEOLESSON',
  xApi: 'CTTY_20',
};

export const CONTENTS_XOD = ['CTTY_01', 'CTTY_05', 'CTTY_12'];
export const STORAGE_STATUS = { uploadAccepted: 'upload-accepted', uploadDone: 'upload-done', xodStarted: 'xod-started' };
export const VIDEOLESSON_CONTENT_DEFAULT = {
  id: '',
  url: '',
  thumbnailUrl: '',
  duration: '',
  title: '',
  type: '',
  sections: [],
  references: [],
  questions: {},
  preferences: { allowSkipAhead: true, allowRepeatQuestion: true },
};

export const CONTENTS_NOT_ACCEPT_VIEWER = [TYPES_CONTENTS.CTTY_LEMONADE, TYPES_CONTENTS.app, TYPES_CONTENTS.test, TYPES_CONTENTS.CTTY_VIDEOLESSON];
export const CONTENTS_ACCEPT_TEMPLATE_COMPONENT = [TYPES_CONTENTS.FIXED_LAYOUT, TYPES_CONTENTS.PDF];

// ---------------------------------------------------------------
// Auxiliary functions
// ---------------------------------------------------------------

function getDataCreate(data) {
  return {
    description: data.description,
    erp_id: data.erp_id ? data.erp_id : null,
    guid: data.guid,
    is_public: data.is_public,
    is_teacher_only: data.is_teacher_only,
    langs: data.langs ? getMetadataArray(data, 'langs') : [],
    mobile_friendly: data.mobile_friendly,
    name: data.name,
    type_guid: data.type_guid,
  };
}

function getDataUpdate(data) {
  return {
    data: data.data,
    description: data.description,
    erp_id: data.erp_id ? data.erp_id : null,
    guid: data.guid,
    is_public: data.is_public,
    is_teacher_only: data.is_teacher_only,
    mobile_friendly: data.mobile_friendly,
    name: data.name,
    question_type_guid: data.question_type_guid,
    status: data.status ? 'active' : 'inactive',
    thumbnail: data.thumbnail ? data.thumbnail : undefined,
    type_guid: data.type_guid,
    url: getAttributeUrl(data),

    // Metadata array:
    collections: data.collections ? getMetadataArray(data, 'collections') : [],
    country_guid: data.country ? data.country.value : undefined,
    customTags: data.customTags ? getMetadataArray(data, 'customTags') : [],
    dependencies: data.dependencies ? getMetadataArray(data, 'dependencies') : [],
    didacticTypes: data.didacticTypes ? getMetadataArray(data, 'didacticTypes') : [],
    disciplines: data.disciplines ? getMetadataArray(data, 'disciplines') : [],
    educationLevels: data.educationLevels ? getMetadataArray(data, 'educationLevels') : [],
    educationYears: data.educationYears ? getMetadataArray(data, 'educationYears') : [],
    langs: data.langs ? getMetadataArray(data, 'langs') : [],
    learningObjectives: data.learningObjectives ? getMetadataArray(data, 'learningObjectives') : [],
    publications: data.publications ? getMetadataArray(data, 'publications') : [],
    tags: data.tags ? getMetadataArray(data, 'tags') : [],
    topics: data.topics ? getMetadataArray(data, 'topics') : [],
  };
}

function getAttributeUrl(data) {
  // En el caso de ficheros no hay que añadir el atributo "url" en los Update porque crea una distorsion en el bulder del fileStore
  const [format, mimeType] = getMimeType(data);
  const isUpdateUrl = !mimeType.length || !format.length;

  return isUpdateUrl ? data.url : undefined;
}

export async function getTags(data) {
  try {
    const tags = data.tags.filter((item) => !item?.__isNew__);
    const tagsNew = data.tags.filter((item) => item.__isNew__).map((item) => item.value);

    if (tagsNew.length) {
      const response = await postTags(tagsNew);
      return { ...data, tags: [...tags, ...response.data.data.tag.map((item) => ({ ...item, value: item.guid }))] };
    }

    return { ...data, tags };
  } catch (error) {
    return data;
  }
}

// ---------------------------------------------------------------
// Logic for the UI
// ---------------------------------------------------------------

export function hasThumbnail(content) {
  return content.type_guid === TYPES_CONTENTS.FIXED_LAYOUT || content.type_guid === TYPES_CONTENTS.PDF || content.type_guid === TYPES_CONTENTS.xApi;
}

export function hasContentUpload({ content, mimeTypes }) {
  return !!mimeTypes.length && content.type_guid !== TYPES_CONTENTS.CTTY_LEMONADE;
}
